import React from 'react'
import Social from '../components/Social'


const AboutComponent = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containerDisplay: 'flex',
        containerFlex: 1,
        spacerDisplay: 'flex',
    }
  }


  componentDidMount() {

  }


  render() {
    return (
        <div>
          <a name={this.props.name}></a>
          <h2 className="about-component-title easy">{this.props.title}</h2>
            <p className="easy intro-quote slider-quote">{this.props.descriptionLead ? <b>{this.props.descriptionLead}</b> : ''} {this.props.description}</p>
          {this.props.description2 ? <p className="easy intro-quote slider-quote">{this.props.description2}</p> : ''}

          {this.props.description3 ? <p className="easy intro-quote slider-quote">{this.props.description3}</p> : ''}
          {this.props.tmfMention ? <p className="easy intro-quote slider-quote">Guided by <a href="https://treymartinezfischer.com"><b>State Representative Trey Martinez Fischer</b></a>, One Texas PAC will not only invest in state house candidates, but also in county parties in order to build sustainable, progressive infrastructure throughout the Lone State State.</p> : ''}
          { this.props.conclusion ? <p className="easy intro-quote slider-quote" style={{paddingBottom: '35px'}}><b>{this.props.conclusion}</b></p> : ''}
          { this.props.social ? <Social className="easy slider-quote"/> : ''}

        </div>
    )
  }
};

export default AboutComponent
