import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import BannerMessage from '../components/BannerMessage'
import TakeActionForm from "../components/TakeActionForm"
import AboutComponent from '../components/AboutComponent'
import BannerButtons from '../components/BannerButtons'
import AboutComponentSlider from '../components/AboutComponentSlider'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
}) => (
  <div className="page-content">
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`
      }}
      id="main-image-container"
    >
        <TakeActionForm/>
    </div>
    <BannerButtons/>
      <div className="is-dark" style={{
          textAlign: 'center',
          padding: '20px'
      }}>
          <BannerMessage title={title}/>
      </div>
    <section className="section section--gradient" style={{padding: 0, margin: 0}}>
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                    <div className="intro-container">
                      <h1 className="intro-container-heading">{heading}</h1>
                        <h2 className="intro-container-subheading">"It Starts in the House."</h2>
                      <p className="intro-quote">"<b>One Texas exists to bring a Democratic majority to the Texas House of Representatives</b> by building party infrastructure, training candidates, and electing Democrats all across the state — ending nearly two decades of dangerous Republican policymaking, back-room dealing, and unchecked corruption.</p>
                      <p className="intro-quote">"<b><u>It all starts here.</u></b> For a limited time, <b>I will be matching your contributions up to $100,000.</b> Join me, and together let’s take back the House.”</p>
                        <h4 className="name-and-title" onClick={() => {window.location.href = 'https://treymartinezfischer.com'}} style={{fontFamily: 'adobe-caslon pro, serif', cursor: 'pointer'}}><span style={{fontSize: '0.85em'}}>STATE REPRESENTATIVE</span><br/><span style={{fontSize: '1.25em'}}>Trey Martinez Fischer</span></h4>
                    </div>

                    <div className="tile" style={{height: '350px', overflowY: 'auto', border: '1px solid #dbdbdb', marginTop: '25px', borderRadius: '2px'}}>
                        <AboutComponentSlider />
                    </div>


                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
