import React from 'react'

const TakeActionForm = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      containerDisplay: 'flex',
        containerFlex: 1,
        spacerDisplay: 'flex',
        logoOpacity: 0,
        logoWidth: '100%',
        displayForm: '',
        response: 'Thank you for signing up for updates from One Texas PAC.',
        displayResponse: 'none',
        responseOpacity: 0,
    }


  }



  componentDidMount() {
    this.setState({logoOpacity: 0});

    setTimeout(() => {
        this.setState({logoOpacity: 1})
    }, 1000);



  }


  render() {
    return (
        <div className="take-action-form-container">
            <div className="take-action-form-spacer">
            </div>
            <div className="take-action-form-subcontainer" style={{float: 'right'}}>
                <img style={{opacity: this.state.logoOpacity, width: this.state.logoWidth, cursor: 'pointer', transition: '3s all ease'}} src='/img/Logo_white text.svg' onClick={() => {window.location.href = "/donate"}}/>
                <div id="take-action-form-form-response" className="take-action-form-form take-action-form-form-response" style={{width: '98%', display: this.state.displayResponse, textAlign: 'center', transition: '1s all ease', opacity: this.state.responseOpacity}}>
                    <p style={{backgroundColor: 'rgba(0,0,0,0.2)', float: 'right', textAlign: 'center', color: 'white', fontWeight: 'bold', fontStyle: 'italic', padding: '5px', transition: '1s all ease'}}>{this.state.response}</p>
                </div>
                <div id="take-action-form-form" className="take-action-form-form" style={{width: '98%', display: this.state.displayForm, transition: '1s all ease'}}>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                        <div className="take-action-form-form-spacer">
                        </div>
                        <div className="take-action-form-form-form">
                            <form action="https://gmail.us5.list-manage.com/subscribe/post?u=47d27de972490a81d6d877000&amp;id=f92e07450c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate onSubmit={() => {this.setState(state => ({displayForm: 'none', displayResponse: ''})); setTimeout(() => {this.setState({responseOpacity: 1})}, 1000); return false}} >
                                <input className='sign-up-form-email' type="email" required placeholder="Email address" name="EMAIL" id="mce-EMAIL"/>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input className="sign-up-form-zip" type="text" placeholder="ZIP code" name="ZIP" id="mce-ZIP" />
                                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input
                                        type="text" name="b_47d27de972490a81d6d877000_f92e07450c" tabIndex="-1"
                                        value=""/></div>

                                    <div style={{flex: 0.05}}>
                                    </div>
                                    <button className="submit-btn" type="submit" onClick={() => function() {return false}}>I'M IN</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
  }
};

export default TakeActionForm
