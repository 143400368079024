import React from 'react'
import AboutComponent from "./AboutComponent";


const AboutComponentSlider = class extends React.Component {
  constructor(props) {
    super(props)
    const sliderStates = [{
      heading: 'One Vision',
      descriptionLead: 'From Lyndon B. Johnson to Ann Richards, Texas Democrats have always been fighters',
      description: '— and in 2020, we’re gearing up for the biggest fight in a decade.',
      id: 'vision',
      name: 'vision',
      description2: 'The Texas House, held by Republicans since 2002, is now within inches of a Democratic comeback.',
      description3: 'The Texas Legislature has been the birthplace of American giants, from Sam Rayburn to Barbara Jordan—visionary leaders who had dreams for Texas as big as the Lone Star State. As the mantle now passes to a new generation, we have a responsibility to uphold that awesome legacy.',
      conclusion: '',
      social: false,
      tmfMention: false
    },
      {
        heading: 'One Plan',
        descriptionLead: 'Nine seats.',
        description: 'Nine seats are all that stand in the way of a Democratic majority in the Texas House. A majority that fights for our schools. A majority that will work to expand healthcare access. A majority that gives every Texas family a fair shot to succeed.',
        id: 'plan',
        name: 'plan',
        description2: 'One Texas PAC is focused on ensuring our House Democratic candidates have all the resources they need to turn Texas blue by investing early, providing campaign support, and running a robust digital operation to take the fight to the Republican Party.',
        conclusion: 'That\'s how we build our bench. That’s how we take back the Texas House.',
        social: false,
        tmfMention: true
      },
      {
        heading: 'One Team',
        descriptionLead: 'One Texas PAC',
        description: 'will be announcing the campaigns that we are supporting in the weeks and months to come.',
        id: 'team',
        name: 'team',
        description2: 'Follow us on social media, sign up for e-mail blasts and check this website for the latest updates on what we are up to:',
        description3: '',
        conclusion: '',
        social: true,
        tmfMention: false
      }]

    this.state = {
      heading: sliderStates[0].heading,
      id: sliderStates[0].id,
      name: sliderStates[0].name,
      descriptionLead: sliderStates[0].descriptionLead,
      description: sliderStates[0].description,
      description2: sliderStates[0].description2,
      description3: sliderStates[0].description3,
      conclusion: sliderStates[0].conclusion,
      social: sliderStates[0].social,
      currentId: sliderStates[0].id,
      sliderStates: sliderStates,
      sliderOpacity: 1,
      activeSlide: true,
      stopSlider: false,
      tmfMention: sliderStates[0].tmfMention,
      summonLinkName: ''
    };

  }

  nextSliderState = () => {
//    console.log(this.state);
    if (this.state.stopSlider === true) {
      this.setState({activeSlide: false});
    }
    const currentIndex = this.state.sliderStates.findIndex(o => o.id === this.state.currentId)
    let newIndex;
    if (currentIndex <= 1) {
      newIndex = currentIndex + 1;
    } else {
      newIndex = 0;
    }
    setTimeout(() => this.setState({sliderOpacity: 0}), 250 )
    setTimeout(() => {
      this.setState({
        heading: this.state.sliderStates[newIndex].heading,
        id: this.state.sliderStates[newIndex].id,
        name: this.state.sliderStates[newIndex].name,
        descriptionLead: this.state.sliderStates[newIndex].descriptionLead,
        description: this.state.sliderStates[newIndex].description,
        description2: this.state.sliderStates[newIndex].description2,
        description3: this.state.sliderStates[newIndex].description3,
        conclusion: this.state.sliderStates[newIndex].conclusion,
        social: this.state.sliderStates[newIndex].social,
        currentId: this.state.sliderStates[newIndex].id,
        tmfMention: this.state.sliderStates[newIndex].tmfMention
      });
      this.setState({sliderOpacity: 1});
    }, 1250);

  };

  previousSliderState = () => {
    if (this.state.stopSlider === true) {
      this.setState({activeSlide: false});
    }
    const currentIndex = this.state.sliderStates.findIndex(o => o.id === this.state.currentId);
    let newIndex;
    if (currentIndex >= 1) {
      newIndex = currentIndex - 1;
    } else {
      newIndex = 2;
    }
    setTimeout(() => this.setState({sliderOpacity: 0}), 250 )
    setTimeout(() => {
      this.setState({
        heading: this.state.sliderStates[newIndex].heading,
        id: this.state.sliderStates[newIndex].id,
        name: this.state.sliderStates[newIndex].name,
        descriptionLead: this.state.sliderStates[newIndex].descriptionLead,
        description: this.state.sliderStates[newIndex].description,
        description2: this.state.sliderStates[newIndex].description2,
        description3: this.state.sliderStates[newIndex].description3,
        conclusion: this.state.sliderStates[newIndex].conclusion,
        social: this.state.sliderStates[newIndex].social,
        currentId: this.state.sliderStates[newIndex].id,
        tmfMention: this.state.sliderStates[newIndex].tmfMention

      });
      this.setState({sliderOpacity: 1});
    }, 1250);
  };

  setSliderState = (linkName) => {
    /* let linkNameRaw = document.cookie;
    console.log(linkNameRaw);
    const linkName = linkNameCookie.split("=")[1]; */
    // console.log (linkName + "to handle");
    // this.setSliderState(linkName)
    this.setState({activeSlide: false, stopSlider: true, summonLinkName: linkName});
    const linkMap = {
      "vision": 0,
      "plan": 1,
      "team": 2
    };
    let newIndex = Object.keys(linkMap).find(key => key === linkName);
    newIndex = linkMap[newIndex]
    // console.log(newIndex)
    setTimeout(() => this.setState({sliderOpacity: 0}), 250 );
    // console.log(this.state.sliderStates)
    // this.setState({heading: sliderStates[newIndex]})
    setTimeout(() => {
      this.setState({
        heading: this.state.sliderStates[newIndex].heading,
        id: this.state.sliderStates[newIndex].id,
        name: this.state.sliderStates[newIndex].name,
        descriptionLead: this.state.sliderStates[newIndex].descriptionLead,
        description: this.state.sliderStates[newIndex].description,
        description2: this.state.sliderStates[newIndex].description2,
        description3: this.state.sliderStates[newIndex].description3,
        conclusion: this.state.sliderStates[newIndex].conclusion,
        social: this.state.sliderStates[newIndex].social,
        currentId: this.state.sliderStates[newIndex].id,
        tmfMention: this.state.sliderStates[newIndex].tmfMention

      });
      this.setState({sliderOpacity: 1});
    }, 1250);
  };

  componentDidMount() {
    var event = new Event('onchange');
    var el = document.getElementById("link-name-placeholder")
    el.addEventListener("onchange", (e) => this.setSliderState(e.target.value));


    if (this.state.activeSlide === true) {
      const intervalId = setInterval(() => {
        if (this.state.stopSlider === true) {
          clearInterval(intervalId);
      } else {
          this.nextSliderState()
        }
      }, 13000)
    }

  }


  render() {
    return (
        <div name="slider" id="slider" className="slider-container easy" style={{display: 'flex', flex: 1, opacity: this.state.sliderOpacity}}>
          <div style={{flex: 0.1, display: 'flex', textAlign: 'left', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <span className="slider-selector" style={{alignSelf: 'center', float: 'left',}} onClick={()=> {this.setState({stopSlider: true}); this.previousSliderState()}}>◀</span>
          </div>
          <div style={{flex: 0.8,}}>
            <AboutComponent tmfMention={this.state.tmfMention} id={this.state.id} name={this.state.name} description={this.state.description} title={this.state.heading} descriptionLead={this.state.descriptionLead} description2={this.state.description2} description3={this.state.description3} conclusion={this.state.conclusion} social={this.state.social}/>
          </div>
          <div style={{flex: 0.1, display: 'flex', textAlign: 'right', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <span className="slider-selector" style={{alignSelf: 'center', float: 'right'}} onClick={() => {this.setState({stopSlider: true}); this.nextSliderState()}}>▶</span>
          </div>
          <div>
            <form style={{display: 'none'}}>
              <input onChange={(e) => {
                console.log(e + " e")
              }} type="hidden" name="link-name-placeholder" id="link-name-placeholder" value={this.state.summonLinkName}/>
            </form>
            </div>
        </div>
    )
  }
};

export default AboutComponentSlider

/*
(e) => {console.log(e); console.log(this.state.summonLinkName + "to handle")}
 */