import React from 'react'
import facebook from "../img/social/facebook.svg";
import twitter from "../img/social/twitter.svg";
import instagram from "../img/social/instagram.svg";

const Social = class extends React.Component {
  constructor(props) {
    super(props)

  }


  componentDidMount() {

  }


  render() {
    return (

        <div className="social-large" style={{width: '100%', textAlign: 'center', paddingBottom: '35px'}}>
            <a title="facebook" href="https://www.facebook.com/OneTexasPAC/" target="_blank">
                <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1.5em', height: '1.5em', }}
                />
            </a>
            <a title="twitter" href="https://twitter.com/OneTexasPAC" target="_blank">
                <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1.5em', height: '1.5em' }}
                />
            </a>
        </div>

    )
  }
};

export default Social
