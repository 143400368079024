import React from 'react'

const BannerMessage = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opacity: 0,
        color: 'lightgrey'
    }
  }


  componentDidMount() {
    this.setState({
      opacity: 0
    });
    setTimeout(() => {
      this.setState({opacity: 1});
        this.setState({color: 'white'});

    }, 1000)

  }


  render() {
    return (
      <div>
        <h2 style={{
            opacity: this.state.opacity,
            color: this.state.color,
            transition: '3s all ease'
        }} >
          {this.props.title}
        </h2>
      </div>

    )
  }
};

export default BannerMessage
